$(function(){
  $('.mobile-trigger').on('click', function(){
    $(this).toggleClass('active');
    $("#MobileNavigationTrigger, #MobileNavigation").toggleClass('active');
  });
  $('#MobileNavigation ul li.has-children > a, #MobileNavigationFooter ul li.has-children > a').on('click', function(e){
    $(this).closest('li').toggleClass('active');
    if($(this).closest('li').hasClass('active')){
      $(this).closest('li').find('ul').slideDown();
    }else{
      $(this).closest('li').find('ul').slideUp();
    }
    e.preventDefault();
    return false;
  })
})
$(function(){
  $('.site-search-form').on('submit', function(){
    let target = $(this).attr('action');
    target = target + $(this).find('input').val();
    window.location.href = "https://products.shawcity.co.uk" + target;
    return false;
  });
})
$(function(){
  if($('.shopify-site').length){
    // setInterval(() => {
    //   $.ajax({
    //     type: 'GET',
    //     url: 'https://products.shawcity.co.uk/cart.json',
    //     dataType: 'json',
    //     success: function(data) { 
    //         $('.cart-button').text("Cart(" + data.item_count + ")");
    //     }
    // });
    // }, 1000);
  }
});
$(window).on('load', function () {
  var hash = window.location.hash;
  if(hash != ""){
    $('html, body').animate({
        scrollTop: $(hash).offset().top
    }, 'fast');
  }

});